import React from 'react'
import PropTypes from 'prop-types'

const Pagination = ({ total, slideIndex, isMobile, setSlideIndex }) => {
  const currentSlide = slideIndex + 1

  const createMobilePag = () => {
    const pagList = []

    for (let i = 0; i < total; i++) {
      pagList.push(
        <button
          type="button"
          aria-label={`Change to image ${i + 1}`}
          className={`pointer-events-auto mx-1 w-2 h-2 bg-gray-100 border border-white rounded-full ${
            slideIndex === i ? 'bg-blue-500' : ''
          }`}
          onClick={() => setSlideIndex(i)}
        />
      )
    }

    return pagList
  }

  return (
    <>
      {isMobile ? (
        <div className="absolute w-full flex justify-center bottom-0 mb-8 z-100 pointer-events-none">
          {createMobilePag()}
        </div>
      ) : (
        <div className="absolute w-full flex justify-center bottom-0 mb-8 z-100 pointer-events-none">
          <div className="bg-white bg-opacity-50 rounded-lg px-3 pt-3 pb-2 text-14 font-bold leading-none">
            {currentSlide} / {total}
          </div>
        </div>
      )}
    </>
  )
}

export default Pagination

Pagination.propTypes = {
  total: PropTypes.number,
  slideIndex: PropTypes.number,
  isMobile: PropTypes.bool,
}
